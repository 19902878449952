/*----------------------------------------*/
/*  07. Pages CSS
/*----------------------------------------*/
@import "pages/blog";
@import "pages/shop";
@import "pages/single-product";
@import "pages/my-account";
@import "pages/login-register";
@import "pages/wishlist";
@import "pages/cart";
@import "pages/compare";
@import "pages/checkout";
@import "pages/contact";
@import "pages/about";
@import "pages/404-error";
@import "pages/faq";